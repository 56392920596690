(function ($) {
  // Cannot be used more than once on a page...
  $.endlessScrollControl = function ($element, opt) {
    var plugin = this,
      currentPage = 0,
      currentLastPage = 0,
      lastPage = -1,
      scrollDelay,
      renderProviders,
      maxZoom,
      minZoom,
      dragScollData,
      $pageInd,
      $scrollElement,
      $busyElement,
      $errorElement,
      pageIndTimeout,
      pinchPositionX = 0,
      pinchPositionY = 0,
      pinchInitialPositionX = 0,
      pinchInitialPositionY = 0,
      pinchOriginX = 0,
      pinchOriginY = 0,
      pinchStartX = 0,
      pinchStartY = 0,
      pinchInitialScale = 1,
      pinchInitialDistance = 0,
      pinchDistance = 0,
      pinchScale = 1,
      pinchRelativeScale = 1,
      pinchMode = '',
      elementInitialWidth = -1;
    plugin.options = opt;

    var defaults = {
      thumbnailUrl: "",
      blankImgUrl: "",
      thumbnailType: "image/png",      
      pagerText: "%page% of %count%",
      scrollElement: $(window),
      clickDragScrolling: true,
      showButtons: true,
      canPinchZoom: true,
      maxStartWidth: 720,
      maxZoom: 450,
      minZoom: 50,
      zoom: 100,
      pageChange: null,
      backClick: null,
      pageSwitch: null, // is called if active page changes
      lastPageSwitch: null, // is called if the last displayed page changes
      rightMargin: 50,
      tooltip_back: '',
      tooltip_zoomWidth: '',
      tooltip_zoomIn: '',
      tooltip_zoomOut: '',
      tooltip_download: '',
      message_cannotDisplay: '',
      message_clickToDownload: ''
    };

    plugin.init = function () {
      plugin.settings = $.extend({}, defaults, plugin.options);

      //console.debug('endlesssSrollControl init for document: ' + plugin.settings.documentId);

      $scrollElement = plugin.settings.scrollElement;
      $busyElement = plugin.settings.busyElement;
      $errorElement = plugin.settings.errorElement;

      $scrollElement.unbind("scroll");
      $(window).off("resize", pageScroll);
     
      $element.off();
      $element.empty();

      $element.append("<div class='paperBorder left'/>");
      $element.append("<div class='paperBorder right'/>");

      if (plugin.settings.canPinchZoom)
        $element.css({ '-webkit-transform-origin': "0 0", 'transform-origin': "0 0" });

      if (plugin.settings.backClick)
        addBackButton($element, plugin.settings.backClick);

      addViewerButtons($scrollElement.parent());

      //maxZoom = plugin.settings.maxZoom;
      //minZoom = plugin.settings.minZoom;

      elementInitialWidth = $element.width();
     
      if ($pageInd) { $pageInd.remove(); }
      $pageInd = addPageIndicator($element.parent());

      // Reset zoom
      plugin.settings.zoom = 100;
      transformElement(1, 0, 0, 0);

      if (plugin.settings.clickDragScrolling) {
        $scrollElement.on("mousedown", startDragScrolling);
      }

      let startWidth = Math.min($scrollElement.width() - 40, plugin.settings.maxStartWidth);
      // Enforce minimum width of 350px
      startWidth = Math.max(350, startWidth);

      // Determine min / max zoom based on startWidth with 100% = 1024px
      maxZoom = Math.round(180000 / startWidth);
      minZoom = Math.min(Math.round(40000 / startWidth), 100);

      //console.debug('EndlessScrollrenderer Start Width: ' + startWidth + '; maxZoom: ' + maxZoom + '; minZoom: ' + minZoom);

      showBusy();

      if (plugin.settings.renderProviders) {
        // Get thumbnail for first page...
        plugin.settings.renderProviders.getThumbnail$(1, startWidth).subscribe(thumbnailData => {
          // First make sure the data is meant for the currently active document
          if (thumbnailData.renderId !== plugin.settings.documentId) {
            //console.debug('Ignore thumbnail data. Current document: ' + plugin.settings.documentId + '; data for document: ' + thumbnailData.renderId);
            return;
          }

          // Check wether or not the pageDetails are already set
          // Only proceed if they are not...
          if (!plugin.settings.pageDetails) {
            //var displayDpi = getDisplayDpi(thumbnailData.width, startWidth);
            
            plugin.settings.pageDetails = [];

            for (let i = 0; i < thumbnailData.pageCount; i++) {
              plugin.settings.pageDetails.push({                
                Width: thumbnailData.width,
                Height: thumbnailData.height,
                ID: "pageImg_" + i
              });
            }

            //console.debug('Thumbnail data first page size: ' + plugin.settings.pageDetails[0].Width + ", " + plugin.settings.pageDetails[0].Height);

            setupPages(plugin.settings.pageDetails);
          }

          // Set img src for first thumbnail
          let $img = $("#pageImg_0");
          updateImg($img, startWidth, thumbnailData.image);

          if (plugin.settings.onDocumentReady) {
            plugin.settings.onDocumentReady();
          }

          // Signal scroll subscribers
          // pageScroll();

          hideMessages();        
        }, error => {
          // Get of first thumbnail failed
          showError();
        });

        // ... and at the same time: get details for all pages
        plugin.settings.renderProviders.getRenderDetails$.subscribe(documentDetails => {

          // First make sure the data is meant for the currently active document
          if (documentDetails.renderId !== plugin.settings.documentId) {
            //console.debug('Ignore documentDetails. Current document: ' + plugin.settings.documentId + '; data for document: ' + documentDetails.documentId);
            return;
          }

          var displayDpi = getDisplayDpi(documentDetails.pageDetails[0].width, startWidth);

          plugin.settings.pageDetails = [];
          for (let i = 0; i < documentDetails.pageDetails.length; i++) {
            var pxWidth = Math.round((documentDetails.pageDetails[i].width * displayDpi) / 1440.0);
            var pxHeight = Math.round((documentDetails.pageDetails[i].height * pxWidth) / documentDetails.pageDetails[i].width);

            plugin.settings.pageDetails.push({              
              Width: pxWidth,
              Height: pxHeight,
              ID: "pageImg_" + i
            });
          }

          //console.debug('RenderDetails data first page size: ' + plugin.settings.pageDetails[0].Width + ", " + plugin.settings.pageDetails[0].Height);
          
          setupPages(plugin.settings.pageDetails);

          setSrcOfVisibleImgs(plugin.settings.pageDetails, plugin.settings.documentId, false, true);

          // Signal scroll subscribers
          pageScroll();

          hideMessages();
        }, error => {
          showError();
        });
        
      }

      return this;
    };

    function setSrcOfVisibleImgs(pageDetails, currentDocumentId, force, skipFirstPage = false) {
      //console.debug('setSrcOfVisibleImgs force:' + force);
      var zoom = plugin.settings.zoom;
      for (var i = 0; i < pageDetails.length; i++) {
        let $img = $("#pageImg_" + i);        
        const imgVisibility = getImgVisibility($img);
        var imgVisible = imgVisibility.visible;
        var imgPixOutOfView = Math.abs(imgVisibility.visiblePart);

        var singlePageDetails = pageDetails[i];
        var displayWidth = Math.round(singlePageDetails.Width * zoom / 100);
        var currentWidth = parseInt($img.attr("mywidth") || '0');
        
        // Only update image if new displayWidth is bigger than currentWidth
        if ((!skipFirstPage || i !== 0) && currentWidth < displayWidth && (imgVisible || imgPixOutOfView < 150)) {

          // Update the image with the new width to prevent double requests
          $img.attr("mywidth", displayWidth);

          // Retrieve thumbnail
          plugin.settings.renderProviders.getThumbnail$(i + 1, displayWidth).subscribe(thumbnailData => {
            // First make sure the data is meant for the currently active document
            if (thumbnailData.renderId !== currentDocumentId) {
              //console.debug('Ignore thumbnail data. Current document: ' + currentDocumentId + '; data for document: ' + thumbnailData.renderId);
              return;
            }

            let $myImg = $("#pageImg_" + (thumbnailData.pageIndex - 1));
            updateImg($myImg, thumbnailData.width, thumbnailData.image);
          });       
        }
      }
    }

    function updateImg($img, mywidth, imageBlob) {
      $img.attr("mywidth", mywidth);
      var pageUrl = URL.createObjectURL(imageBlob);
      $img.attr("src", pageUrl);
      $img.attr("toUpdate", "false");       
    }

    function setupPages(pageDetails) {
      $scrollElement.unbind("scroll");      

      // Add all pages or adjust size if already added
      for (var i = 0; i < pageDetails.length; i++) {
        initPage($element, i, pageDetails[i], plugin.settings.zoom);
      }

      // $scrollElement.scrollTop(0);
      hidePageIndicator();
      internalPageChange(1);

      $scrollElement.on("scroll", function () { pageScroll(); });
      $(window).on("resize", pageScroll );
    }

    plugin.zoom = function (newZoom) {
      //console.debug('Set zoom from te ouside: newZoom: ' + newZoom);
      newZoom = Math.max(newZoom, minZoom);
      newZoom = Math.min(newZoom, maxZoom);

      var startX = ($scrollElement.width() / 2);
      var startY = ($scrollElement.height() / 2);

      var originX = startX + $scrollElement.scrollLeft();
      var originY = startY + $scrollElement.scrollTop();

      var initialPositionX = $scrollElement.scrollLeft() * -1;
      var initialPositionY = $scrollElement.scrollTop() * -1;

      var relativeScale = newZoom / plugin.settings.zoom;

      var positionX = originX * (1 - relativeScale) + initialPositionX;
      var positionY = originY * (1 - relativeScale) + initialPositionY;

      transformElement(newZoom / 100, positionX, positionY, 0);
      plugin.settings.zoom = newZoom;
      pinchScale = newZoom / 100;
    };

    plugin.gotoPage = function (pageIndex) {
      var $img = $("#pageImg_" + pageIndex);
      if ($img.length > 0)
        $element.scrollTop($img[0].offsetTop);
    };

    plugin.setRenderDetailsProvider = function(prov) {
      // test
      //prov.subscribe();
    }

    plugin.setThumbnailProvider = function(prov) {
      
    }

    var f = function () {
      setSrcOfVisibleImgs(plugin.settings.pageDetails, plugin.settings.documentId, false);
      scrollDelay = null;
    };

    // function renderDownload($el, docType) {
    //   var $phcont = $("<div class='page-container download-area' />");
    //   $phcont.append("<div class='text'>" + plugin.settings.message_cannotDisplay + "</div>");
    //   $phcont.append("<div class='text'>" + plugin.settings.message_clickToDownload + "</div>");
    //   $phcont.append("<a class='link' href='" + plugin.settings.downloadUrl + "'>" + plugin.settings.tooltip_download + "</a>");
    //   $el.append($phcont);
    // }

    function getPagePxSize(pxSize, zoom) {
      return {
        Width: Math.round((pxSize.Width * zoom) / 100),
        Height: Math.round((pxSize.Height * zoom) / 100),
      };
    }

    // Add page to DOM
    // or update its size if it is already present
    function initPage($el, index, pageDetails, zoom) {
      lastPage = index;

      let pagePxSize = getPagePxSize(pageDetails, zoom)
      let pagePresent = $("#pageImg_" + index).length > 0;
      
      if (!pagePresent) {
        var $phcont = $("<div id='pc_pageImg_" + index + "' class='page-container'/>");
        var $phdiv = $("<div id='ph_pageImg_" + index + "' class='img-placeholder page-loader-icon'/>");
        $phdiv.height(pagePxSize.Height).width(pagePxSize.Width);
        var $img = $("<img id='pageImg_" + index + "' src='" + plugin.settings.blankImgUrl + "' />");
        $img.attr("toUpdate", "true").attr("zoom", "0").height(pagePxSize.Height).width(pagePxSize.Width);

        if (plugin.settings.canPinchZoom) {
          $img[0].addEventListener('touchstart', touchstartHandler);
          $img[0].addEventListener('touchmove', touchmoveHandler);
          $img[0].addEventListener('touchend', touchendHandler);
        }
        $phdiv.append($img);

        $phcont.append($phdiv);
        $el.append($phcont);
      } else {
        // Page already added: update size
        var $phcont = $("#pc_pageImg_" + index);
        var $phdiv = $("#ph_pageImg_" + index);
        $phdiv.height(pagePxSize.Height).width(pagePxSize.Width);
        var $img = $("#pageImg_" + index);
        $img.height(pagePxSize.Height).width(pagePxSize.Width);
        //$img.attr("toUpdate", "true");
      }
    }

    function getDistance(touches) {
      if (touches.length !== 2) return 0;

      var d = Math.sqrt(Math.pow(touches[0].clientX - touches[1].clientX, 2) +
        Math.pow(touches[0].clientY - touches[1].clientY, 2));
      return parseInt(d, 10);
    }

    function transformElement(scale, orgX, orgY, duration) {
      var transition = duration ? 'all cubic-bezier(0,0,.5,1) ' + duration + 's' : '';
      var scaleCss = "scale(" + scale + ")";

      $element.css({
        '-webkit-transition': transition,
        transition: transition,
        '-webkit-transform': scaleCss,
        transform: scaleCss
      });

      // $element.parent().height($element.height() * scale);
      // $element.parent().width(elementInitialWidth * scale);

      $scrollElement.scrollLeft(-orgX);
      $scrollElement.scrollTop(-orgY);
    }

    function touchstartHandler(evt) {
      var touches = evt.originalEvent ? evt.originalEvent.touches : evt.touches;

      // Calculate startpoint relative to parent of image
      var parentOffset = $scrollElement.offset();

      pinchStartX = touches[0].clientX - parentOffset.left;
      pinchStartY = touches[0].clientY - parentOffset.top;
      pinchInitialPositionX = $scrollElement.scrollLeft() * -1;
      pinchInitialPositionY = $scrollElement.scrollTop() * -1;
    }

    function touchmoveHandler(evt) {
      var touches = evt.originalEvent ? evt.originalEvent.touches : evt.touches;

      if (pinchMode === '' && touches.length === 2) {
        pinchMode = 'pinch';

        pinchInitialScale = pinchScale;
        pinchInitialDistance = getDistance(touches);
        pinchOriginX = pinchStartX - parseInt((touches[0].clientX - touches[1].clientX) / 2, 10) - pinchInitialPositionX;
        pinchOriginY = pinchStartY - parseInt((touches[0].clientY - touches[1].clientY) / 2, 10) - pinchInitialPositionY;
      }

      if (pinchMode === 'pinch' && touches.length === 2) {
        evt.preventDefault();

        pinchDistance = getDistance(touches);
        pinchRelativeScale = pinchDistance / pinchInitialDistance;
        pinchScale = pinchRelativeScale * pinchInitialScale;

        if (pinchScale > maxZoom / 100) {
          pinchScale = maxZoom / 100;
          pinchRelativeScale = pinchScale / pinchInitialScale;
        }

        if (pinchScale < minZoom / 100) {
          pinchScale = minZoom / 100;
          pinchRelativeScale = pinchScale / pinchInitialScale;
        }

        pinchPositionX = pinchOriginX * (1 - pinchRelativeScale) + pinchInitialPositionX;
        pinchPositionY = pinchOriginY * (1 - pinchRelativeScale) + pinchInitialPositionY;

        transformElement(pinchScale, pinchPositionX, pinchPositionY);
      }
    }

    function touchendHandler(evt) {
      var touches = evt.originalEvent ? evt.originalEvent.touches : evt.touches;

      if (pinchMode === '' || touches.length > 0) {
        return;
      }

      plugin.settings.zoom = pinchScale * 100;

      setSrcOfVisibleImgs(plugin.settings.pageDetails, plugin.settings.documentId, false);

      pinchMode = '';

      // Update active page
      var newPage = determineActivePage();
      if (newPage != currentPage)
        internalPageChange(newPage);
    }

    function addBackButton($el, func) {
      var $bb = $("<div class='viewerBackButton' />");
      $bb.attr("title", plugin.settings.tooltip_back);
      $bb.on("click", function () {
        if (scrollDelay) clearTimeout(scrollDelay);
        scrollDelay = null;
        func();
      });
      $el.append($bb);
    }

    function addViewerButtons($el) {      
      var $bh = $("<div class='viewerButtons' />");
      var $b = $("<div class='viewerButton icon-zoomWidth-30'/>");
      $b.attr("title", plugin.settings.tooltip_zoomWidth);
      $b.on("click", function () { zoom(-1); });
      $bh.append($b);
      $b = $("<div class='viewerButton icon-zoomOut-30'/>");
      $b.attr("title", plugin.settings.tooltip_zoomOut);
      $b.on("click", function () { zoom(0.8); });
      $bh.append($b);
      $b = $("<div class='viewerButton icon-zoomIn-30'/>");
      $b.attr("title", plugin.settings.tooltip_zoomIn);
      $b.on("click", function () { zoom(1.25); });
      $bh.append($b);
      if (plugin.settings.downloadUrl) {
        $bh.append($("<div class='viewerSeparator'/>"));
        $b = $("<a class='viewerButton icon-download-30' href='" + plugin.settings.downloadUrl + "'/>");
        $b.attr("title", plugin.settings.tooltip_download);
        $bh.append($b);
      }
      if (plugin.settings.downloadClick) {
        $bh.append($("<div class='viewerSeparator'/>"));
        $b = $("<a class='viewerButton icon-download-30' href='javascript:;'/>");
        $b.attr("title", plugin.settings.tooltip_download);
        $bh.append($b);

        $b.on('click', function() {plugin.settings.downloadClick()});
      }
      $el.append($bh);
    }

    function addPageIndicator($el) {
      var $ind = $("<div class='page-indicator'/>");
      $el.append($ind);
      return $ind;
    }

    function zoom(f) {          
      //console.debug('zoom(f) met f='+f);
      var newZoom = f === -1 ? 100 : Math.min(Math.round(plugin.settings.zoom * f), maxZoom);
      plugin.zoom(newZoom);

      // scroll position could have changed
      pageScroll();
    }

    function showBusy(show) {
      $busyElement.show();
      $errorElement.hide();
    }

    function showError() {
      $busyElement.hide();
      $errorElement.show();
    }

    function hideMessages() {
      $errorElement.hide();
      $busyElement.hide();
    }

    function pageScroll() {
      var newScrollPos = $scrollElement.scrollTop();
      if (typeof $scrollElement.lastScrollPos == 'undefined')
        $scrollElement.lastScrollPos = 0;
      var scrollDelta = newScrollPos - $scrollElement.lastScrollPos;

      // console.debug('Scrolling - scrollDelta: ' + scrollDelta);

      // if (scrollDelta == 0) return; // fix for some android browsers
      $scrollElement.lastScrollPos = newScrollPos;

      var newPage = determineActivePage();
      if (newPage !== currentPage)
        internalPageChange(newPage);

      newPage = determineLastVisiblePage();
      if (newPage !== 0 && newPage !== currentLastPage && plugin.settings.lastPageSwitch) {
        plugin.settings.lastPageSwitch(newPage, plugin.settings.pageDetails.length);
      }

      if (plugin.settings.onScroll) {        
        // If no scrollbar: percentage = 100
        var percentage = $scrollElement.get(0).scrollHeight > $scrollElement.height() ? 
        Math.round(newScrollPos * 100 / ($scrollElement.prop('scrollHeight') - $scrollElement.outerHeight())) : 100;
        plugin.settings.onScroll({page: newPage, percentage: percentage});
      }

      if (scrollDelay) clearTimeout(scrollDelay);
      scrollDelay = setTimeout(f, 100);
    }

    function startDragScrolling(event) {
      // event is mousedown event      
      dragScollData = { startX: event.offsetX, startY: event.offsetY };
      $scrollElement.on('mousemove', dragScrolling);
      $scrollElement.on('mouseup', stopDragScrolling);
    }

    function dragScrolling(event) {
      // event is mousemove (while mouse is down)
      const oldLeft = $scrollElement.scrollLeft();
      const oldTop = $scrollElement.scrollTop();
      const newLeft = oldLeft + (dragScollData.startX - event.offsetX);
      const newTop = oldTop + (dragScollData.startY - event.offsetY);

      $scrollElement.scrollLeft(newLeft);
      $scrollElement.scrollTop(newTop);

      // Handle scroll edges
      dragScollData.startX += $scrollElement.scrollLeft() - newLeft;
      dragScollData.startY += $scrollElement.scrollTop() - newTop;
    }

    function stopDragScrolling() {
      $scrollElement.unbind("mousemove");
      $scrollElement.unbind("mouseup");
      dragScollData = null;
    }

    function internalPageChange(newPage) {

      if (plugin.settings.pageSwitch)
        plugin.settings.pageSwitch(newPage, plugin.settings.pageDetails.length);

      currentPage = newPage;

      if (!$pageInd.is(':visible'))
        $pageInd.fadeIn();

      if (pageIndTimeout) clearTimeout(pageIndTimeout);
      pageIndTimeout = setTimeout(hidePageIndicator, 2000);
      
      $pageInd.html(plugin.settings.pagerText.replace('%page%', newPage).replace('%count%', plugin.settings.pageDetails.length));

      // console.debug('internalPageChange ' + newPage + " of " + plugin.settings.pageDetails.length);

      if (plugin.settings.pageChange)
        plugin.settings.pageChange(newPage);
    }

    function hidePageIndicator() { $pageInd.fadeOut(); }

    function getImgVisibility($img) {
      if ($img.length == 0) return 0;
      var elementHeight = $scrollElement.height() > 0 ? $scrollElement.height() : 50;
      var docViewTop = $scrollElement.scrollTop();
      var docViewBottom = docViewTop + elementHeight;

      var elemTop = $img.parent()[0].offsetTop * (plugin.settings.zoom / 100);
      var elemBottom = ($img.parent()[0].offsetTop + $img.height()) * (plugin.settings.zoom / 100);

      //console.debug('scrollElement height: ' + elementHeight + '; elemTop: ' + elemTop + '; elemBottom: ' + elemBottom);
      //console.debug('..plugin.settings.zoom: ' + plugin.settings.zoom)

      var visibleTop = Math.max(docViewTop, elemTop);
      var visibleBottom = Math.min(docViewBottom, elemBottom);

      var topVisible = elemTop >= docViewTop && elemTop <= docViewBottom;
      var bottomVisible = elemBottom >= docViewTop && elemBottom <= docViewBottom;

      var visiblePart = Math.round((visibleBottom - visibleTop) * 100 / elementHeight);

      var pixelsOutOfView = 0;

      //console.debug('getImgVisibility img: ' + $img[0].id + '; topVisible: ' + topVisible + '; bottomVisible: ' + bottomVisible + '; visiblePart: ' + visiblePart);

      return {
        visiblePart: visiblePart,
        visible: visiblePart > 0,
        topVisible: topVisible,
        bottomVisible: bottomVisible
      };
    }

    function determineActivePage() {
      var maxVisible = 0;
      var pageDetails = plugin.settings.pageDetails;
      for (var i = 0; i < pageDetails.length; i++) {
        var $img = $("#pageImg_" + i);
        var visiblePart = getImgVisibility($img).visiblePart;
        if (visiblePart > maxVisible)
          maxVisible = visiblePart;
        else if (maxVisible !== 0)
          return i;
      }

      return pageDetails.length;
    }

    function determineLastVisiblePage() {
      var pageDetails = plugin.settings.pageDetails;
      for (var i = pageDetails.length - 1; i >= 0; i--) {
        var $img = $("#pageImg_" + i);
        if (getImgVisibility($img).bottomVisible) {
          return i + 1;
        }
      }

      return 0;
    }

    function getDisplayDpi(pageWidth, availableWidth) {      
      return (availableWidth * 1440) / pageWidth;
    }

    return plugin.init();
  };

  $.fn.endlessScrollControl = function (options) {
    // Iterate each matched element.
    return this.each(function () {
      var plugin;
      if (undefined == $(this).data('endlessScrollControl')) {
        plugin = new $.endlessScrollControl($(this), options);
        $(this).data('endlessScrollControl', plugin);
      } else {
        plugin = $(this).data('endlessScrollControl');
        plugin.options = options;
        plugin.init();
      }
    });
  };

})(jQuery);
